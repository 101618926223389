import { createContent, updateContent } from "../services/ContentService";
import { ErrorTypes, NEW_CONTENT_ID } from "./definitions";

function isCoordinateInIsrael(lat, lon) {
  return lat >= 29.45 && lat <= 33.34 && lon >= 34.27 && lon <= 35.89;
}

function isCoordsFormat(inputString) {
  const regex = /^(\d+(\.\d*)?|\.\d+), (\d+(\.\d*)?|\.\d+)$/;

  return regex.test(inputString);
}

export const calcInitialValues = (content, user) => {
  const values = { is_active: true };
  if (content) {
    values.item_id = content.item_id;
    values.event_type = content.event_type;
    values.header = content.header;
    values.content = content.content;
    values.contact_name = content.contact_name;
    values.contact_email = content.contact_email;
    values.contact_phone = content.contact_phone;
    values.external_link = content.external_link;
    values.report_link = content.report_link;
    values.is_active = !!content.is_active;
    values.subcategory_ids = (content.subcats || []).map((subcat) => subcat.id);
    values.categoryId = content.cat.id;
    if (content.location) {
      values.coordinate = `${content.location.lat}, ${content.location.lon}`;
    }
    values.ynet = !!content.apps?.some((app) => app.name === "ynet");
    values.app_ids = (content.apps || [])?.map((app) => app.id);
  }

  if (!values.app_ids?.length && user.appId) {
    values.app_ids = [user.appId];
  }

  return values;
};

export function validate(values) {
  //Validate here
  const errors = {};
  if (!values.event_type) {
    errors.event_type = "חובה לבחור סוג אירוע";
  }

  if (!values.subcategory_ids?.length) {
    errors.subcategory_ids = "חובה לבחור קטגוריה וקטגוריות משנה";
  }

  if (!values.header) {
    errors.header = "חובה להזין כותרת";
  }

  if (!values.coordinate) {
    errors.coordinate = "חובה להזין נקודת ציון (בפורמט המתאים)";
  } else if (!isCoordsFormat(values.coordinate)) {
    errors.coordinate = "Lat, Lon הקואורדינטה צריכה להיות בפורמט של";
  } else {
    const [lat, lon] = values.coordinate.split(", ").map(parseFloat);
    if (!isCoordinateInIsrael(lat, lon)) {
      errors.coordinate = "הקואורדינטה שהוזנה לא נמצאת בשטח ישראל";
    }
  }

  if (!values.content) {
    errors.content = "חובה להזין תיאור";
  }

  if (!values.app_ids?.length) {
    errors.app_ids = "חובה לבחור פלטפורמה";
  }

  const isValid = Object.keys(errors).length === 0;
  return { errors, isValid };
}

export function onSubmit({ values, onSuccess, setError, user, logout }) {
  const { isValid, errors } = validate(values);
  if (!isValid) {
    setError(ErrorTypes.validationError, errors);
    return;
  }
  const payload = {
    event_type: values.event_type,
    is_active: values.is_active ? 1 : 0,
    header: values.header,
    content: values.content,
    contact_name: values.contact_name,
    contact_phone: values.contact_phone,
    contact_email: values.contact_email,
    external_link: values.external_link,
    report_link: values.report_link,
    app_ids: values.app_ids,
    subcategory_ids: values.subcategory_ids,
    lat: null,
    lon: null,
  };
  if (values.coordinate) {
    [payload.lat, payload.lon] = values.coordinate.split(", ").map(parseFloat);
  }

  let createOrUpdateContent;
  if (values.item_id && values.item_id !== NEW_CONTENT_ID) {
    payload.item_id = values.item_id;
    createOrUpdateContent = updateContent;
  } else {
    createOrUpdateContent = createContent;
  }

  createOrUpdateContent(user, logout, payload)
    .catch((err) => {
      console.error(err);
      setError(ErrorTypes.serverError);
    })
    .then((res) => {
      onSuccess();
    });
}
