import React, { useState, useEffect, useMemo, useContext } from "react";
import { ContentsFilters } from "./ContentsFilters";
import { listContent } from "../services/ContentService";
import { ContentsList } from "./ContentsList";
import LoadingCover from "./LoadingCover";
import { loginContext } from "../LoginContext";

export const ContentsPage = () => {
  const { user, logout } = useContext(loginContext);
  const [contents, setContents] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [catFilter, setCatFilter] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    listContent(user, logout).then((list) => {
      setContents(list);
      setLoaded(true);
    });
  }, [user, logout]);

  const contentsForDisplay = useMemo(() => {
    return contents.filter((content) => {
      if (
        textFilter &&
        !JSON.stringify(content)
          .toLowerCase()
          .includes(textFilter.toLowerCase())
      ) {
        //Means the content doesn't fit the textFilter - we exclude it
        return false;
      }
      if (catFilter && content.cat.id !== +catFilter) {
        //Means the content doesn't fit the category Filter - we exclude it
        return false;
      }

      //Otherwise - include!
      return true;
    });
  }, [contents, textFilter, catFilter]);

  return (
    <div className="container container-lg" dir="rtl" lang="he">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {!loaded && <LoadingCover />}
      <ContentsFilters
        textFilter={textFilter}
        setTextFilter={setTextFilter}
        catFilter={catFilter}
        setCatFilter={setCatFilter}
      />
      {contents && contents.length > 0 && (
        <ContentsList contents={contentsForDisplay} />
      )}
    </div>
  );
};
