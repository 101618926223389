import { useContext } from "react";
import { loginContext } from "../LoginContext";
import { About } from "./About";

function AuthWrapper({ element }) {
  const { user } = useContext(loginContext);

  return user.token ? (
    element
  ) : (
    <div>
      <div className="alert alert-danger text-center p-4" role="alert">
        יש להתחבר כדי לקבל גישה למערכת
      </div>
      <About />
    </div>
  );
}

export default AuthWrapper;
