import { TextFilter } from "./TextFilter";
import { CategoriesFilter } from "./CategoriesFilter";

export const ContentsFilters = ({
  textFilter,
  setTextFilter,
  catFilter,
  setCatFilter,
}) => {
  return (
    <div className="container container-xs mt-2">
      <TextFilter setTextFilter={setTextFilter} textFilter={textFilter} />
      <CategoriesFilter catFilter={catFilter} setCatFilter={setCatFilter} />
    </div>
  );
};
