import { useContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { loginContext } from "../LoginContext";
import { GOOGLE_CLOUD_CLIENT_ID } from "../definitions";
import "./GoogleLoginButton.css";
function GoogleLoginButton() {
  const { login } = useContext(loginContext);
  const [loginFailed, setLoginFailed] = useState(false);

  function onLoginSuccess(googleCredentialToken) {
    if (googleCredentialToken.clientId === GOOGLE_CLOUD_CLIENT_ID) {
      login(googleCredentialToken.credential);
    } else {
      onLoginFail();
    }
  }

  function onLoginFail() {
    setLoginFailed(true);
  }

  return (
    <div className={"google-login-button-container"}>
      <GoogleOAuthProvider clientId={GOOGLE_CLOUD_CLIENT_ID}>
        <GoogleLogin
          locale="he"
          shape="circle"
          size="medium"
          onSuccess={(googleCredentialToken) => onLoginSuccess(googleCredentialToken)}
          onError={onLoginFail}
        />
      </GoogleOAuthProvider>
      {loginFailed ? <div>Login failed. Please try again.</div> : <></>}
    </div>
  );
}

export default GoogleLoginButton;
