import React from "react";
import { getSourceByCategoryId } from "../assets/icons";

// The icon component consumes a description string of
// a category, and renders the corresponding svg.
// In addition, the icon can use a class attribute
// for manipulating its style.
const CategoryIcon = ({ categoryId, className }) => {
  const iconSource = getSourceByCategoryId(categoryId);

  return <img src={iconSource} alt={categoryId} className={className} />;
};

export default CategoryIcon;
