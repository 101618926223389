import { retrieve } from "./utils.js";

// Create a new content
async function createContent({token}, onLogOut, content) {
  try {
    const createdContent = await retrieve(
      token,
      onLogOut,
      "/add",
      "POST",
      content
    );

    return createdContent;
  } catch (error) {
    return null;
  }
}

// Read a specific content item by ID
async function readContent({ token, appId }, onLogOut, contentId) {
  try {
    const content = await retrieve(
      token,
      onLogOut,
      "/fetch",
      "GET",
      undefined,
      { item_id: contentId, app_id: appId || 0 }
    );

    return content[0];
  } catch (error) {
    return null;
  }
}

// Update an existing content item
async function updateContent({ token }, onLogOut, updatedContent) {
  try {
    const updated = await retrieve(
      token,
      onLogOut,
      "/edit",
      "POST",
      updatedContent
    );

    return updated;
  } catch (error) {
    return null;
  }
}

// List all content items
async function listContent({ token, appId }, onLogOut) {
  try {
    const contentList = await retrieve(
      token,
      onLogOut,
      `/fetch`,
      "GET",
      undefined,
      {
        app_id: appId || 0,
      }
    );

    return contentList;
  } catch (error) {
    return [];
  }
}

export { createContent, readContent, updateContent, listContent };
