const baseUrl = "https://map-app.mappo.world:5000";

// Helper function to make a generic API request
// with optional query parameters and request body
async function retrieve(
  token,
  onLogOut,
  endpoint,
  method,
  requestBody = null,
  queryParams = null
) {
  let requestUrl = new URL(`${baseUrl}${endpoint}`);

  if (queryParams) {
    for (let key in queryParams) {
      const value = queryParams[key];

      if (value || value === 0) {
        requestUrl.searchParams.append(key, value);
      }
    }
  }

  try {
    const response = await fetch(requestUrl, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
      body: requestBody ? JSON.stringify(requestBody) : null,
    });

    if (!response.ok) {
      if (response.status === 403) {
        onLogOut();
      }

      throw new Error(`Request failed with status ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("API Request Error:", error);
    throw error;
  }
}

export { retrieve };
