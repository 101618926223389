import React, { useState, useEffect, useContext } from "react";
import { listCategoriesWithSubs } from "../services/CategoryService";
import { loginContext } from "../LoginContext";

export const CategoriesFilter = ({ catFilter, setCatFilter }) => {
  const { user, logout } = useContext(loginContext);
  // get categories list and map it

  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    listCategoriesWithSubs(user, logout).then((categories) => {
      setCategoriesList(categories);
    });
  }, [user, logout]);

  return (
    <div className="input-group mb-3 mt-1">
      <select
        className="form-select categories-select"
        dir="rtl"
        id="inputGroupSelectCategories"
        aria-label="Categories select"
        onChange={(e) => {
          setCatFilter(e.target.value);
        }}
        value={catFilter || ""}
      >
        <option value={""}>בחר קטגוריה</option>
        {categoriesList.map((cat) => (
          <option key={cat.id} value={cat.id}>
            {cat.name}
          </option>
        ))}
      </select>
    </div>
  );
};
