import { Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./About.css";
import roimRachokLogo from "../assets/icons/logo-roim-rachok1.jpg";

export const About = () => {
  return (
    <div dir="rtl" className="container">
      <div>
        <div className="d-block text-center py-3">
          <h1 className="">תכנית רואים רחוק</h1>
          <Image src={roimRachokLogo} className="w-25"></Image>
        </div>
        <p>
          תכנית רואים רחוק היא תכנית חדשנית המכשירה ומלווה בוגרים על הספקטרום
          האוטיסטי בצה"ל ובעבודה, במקצועות נדרשים, בהם מאפייני הספקטרום מהווים
          יתרון.
        </p>
        <p>
          במסגרת התכנית הצבאית של רואים רחוק עוברים המשתתפים קורס הכשרה שמלמד
          אותם את המקצוע, ומקנה להם את כישורי העבודה הנדרשים. בוגרי הקורס
          משתלבים בשירות צבאי במקצוע אותו רכשו, בלווי צוות מקצועי.
        </p>
        <p>
          תחום העבודה בתכנית רואים רחוק נותן מענה לקשיים של בוגרים על הספקטרום
          האוטיסטי בהשתלבות בשוק העבודה. אנחנו מלווים את הבוגרים משלב מציאת
          התפקיד המתאים להם, בתהליך הקליטה בעבודה לאורך כל תקופת התעסוקה.
        </p>
        <p>
          התכנית מקיימת מספר סדנאות לבוגרים המשתתפים ב"רואים רחוק - צבא" המקנות
          כלים לקראת חיים עצמאיים לאחר השירות הצבאי וכהכנה לעולם העבודה. בנוסף
          אנחנו מקיימים פעילויות חברתיות והרצאות, אף לאלה שאינם בוגרי התוכנית.
        </p>
        <p>
          התכנית הוקמה בשנת 2013 והיא מופעלת במשותף ע"י מעבר לאופק (חל"צ) והקריה
          האקדמית אונו. קורס ההכשרה הראשון, שנפתח במסגרת התכנית הוא קורס פענוח
          תצלומי אויר ולוויין, מאחר שלאוכלוסייה על הספקטרום האוטיסטי יש יתרון
          בתחום הוויזואלי, ובנוסף יש לרבים מהם את הסבלנות ויכולת הירידה לפרטים
          הנדרשת לתפקיד זה. בהמשך התרחבה התכנית והיא מכשירה למקצועות נוספים
          לצרכי צה"ל והשוק האזרחי.
        </p>
        <p>
          במסגרת התכנית וקורסי ההכשרה עוסקים בהקניית כישורים ומיומנויות חיים וכן
          במשמעות האישית והקבוצתית של השתייכות לספקטרום האוטיסטי, ולהשלכותיה.
        </p>
      </div>
      <div>
        <h2 className="text-center pt-3">צוות הפיתוח</h2>
        <h4 className="text-center mb-5">פרוייקט אירועים אזרחיים</h4>
        <div className="d-flex flex-wrap justify-content-center">
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                גל
                <br /> מפתח{" "}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                דניאל <br /> מפתח "רואים רחוק"{" "}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                יצחק <br /> מפתח "רואים רחוק"{" "}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                רועי <br /> מפתח "רואים רחוק"{" "}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                שון <br /> מפתח "רואים רחוק"{" "}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="w-25rem m-2 border-0 bg-light card-shadow">
            <Card.Body className="my-3">
              <Card.Title className="text-center">
                דידי <br /> ראש צוות "רואים רחוק"{" "}
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
