import { ContentRow } from "./ContentRow";
import { useNavigate } from "react-router-dom";
import './ContentsList.css'


export const ContentsList = ({ contents }) => {
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    navigate(`/content/${id}`);
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover" dir="rtl">
        <thead>
          <tr>
            <th scope="col">סוג האירוע</th>
            <th scope="col">כותרת</th>
            <th scope="col">כתובת</th>
            <th scope="col">אתר אינטרנט</th>
            <th scope="col">לינק לדיווח</th>
            <th scope="col">יעד לדיווח</th>
            <th scope="col">פעיל</th>
            <th scope="col">קטגוריה</th>
            <th scope="col">lat</th>
            <th scope="col">lon</th>
            <th scope="col">תת-קטגוריות</th>
          </tr>
        </thead>
        <tbody>
          {contents.map((content) => (
              <ContentRow
              key={content.item_id}
              content={content}
              onClick={() => handleRowClick(content.item_id)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};