import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./LoadingCover.css";

const LoadingCover = () => {
  return (
    <div className="loading-cover">
      <Spinner animation="border" role="status" className="loading-cover-spinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingCover;
