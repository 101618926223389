import React from "react";
import "./TextFilter.css";

export const TextFilter = ({ setTextFilter, textFilter }) => {
  return (
    <label dir="rtl" className={'text-filter'}>
      <input
        type="text"
        placeholder="הקלד טקסט כדי לחפש"
        className="form-control search-input"
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
      />
    </label>
  );
};
