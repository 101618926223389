import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import confirmationIconUrl from "../assets/icons/confirmationIcon.jpg";
import "./ConfirmationModal.css";

function ConfirmationModal(props) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/contents");
  };

  return (
    <Modal show={props.show} onHide={handleClose} dir="rtl" >
      <Modal.Header closeButton>
        <Modal.Title className="m-0-auto">להוסיף הצעת עזרה</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="fw-bold mt-5 mb-2">תודה רבה על הוספת ההצעה שלך!</div>
        <div className="mb-3">היא תפורסם לאחר אימותה על ידי הצוות שלנו</div>
        <Image src={confirmationIconUrl} className="pb-12vh"></Image>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={handleClose} className="edit-modal-close-button">
          סגירה
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default ConfirmationModal;
