import React, { useState, useEffect } from "react";
import "./EditedSubCategory.css";

export const EditedSubCategory = ({
  subCategory,
  handleUpdatedSubCategory,
}) => {
  const [subCategoryInput, setSubCategoryInput] = useState("");

  useEffect(() => setSubCategoryInput(subCategory.name), [subCategory.name]);

  return (
    <div className="container edit-size">
      <div className="row">
        <button
          className="btn btn-primary btn-edit-size col-1"
          onClick={() =>
            handleUpdatedSubCategory(subCategory.id, subCategoryInput)
          }
        >
          סיום עריכה
        </button>
        <input
          type="text"
          placeholder="תת-קטגוריה"
          className="form-control col edited-input-bar"
          dir="rtl"
          value={subCategoryInput}
          onChange={(event) => setSubCategoryInput(event.target.value)}
        />
      </div>
    </div>
  );
};
