import React from "react";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
const convertEventToHebrew = (event_type) => {
  let convertedEvent = "אחר";

  switch (event_type) {
    case "offer_help":
      convertedEvent = "הצעה לעזרה";
      break;
    case "need_help":
      convertedEvent = "צריך עזרה";
      break;
    default:
      break;
  }

  return convertedEvent;
};

const shortenString = (string) => {
  const result = string || "";

  if (result.length <= 15) {
    return result;
  }
  return result.substr(0, 15) + "...";
};

export const ContentRow = ({ content, onClick }) => {
  return (
    <tr onClick={onClick} className="cursor-pointer">
      <th scope="row">{convertEventToHebrew(content.event_type)}</th>
      <td>{content.header}</td>
      <td>{content.address}</td>
      <td>{content.external_link}</td>
      <td>{content.report_link}</td>
      <td>{content.apps?.map((app) => app.name).join(", ")}</td>
      <td>
        <Alert
          variant={content.is_active ? "success" : "danger"}
          className="p-1 text-center w-100 m-0"
        >
          <strong>{content.is_active ? "כן" : "לא"}</strong>
        </Alert>
      </td>
      <td>{content.cat.name}</td>
      <td>{content.location?.lat?.toFixed(2) || ""}</td>
      <td>{content.location?.lon?.toFixed(2) || ""}</td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {content.subcats?.map((subcat) => subcat.name).join(", ")}
            </Tooltip>
          }
        >
          <div>
            {shortenString(
              content.subcats?.map((subcat) => subcat.name).join(", ")
            )}
          </div>
        </OverlayTrigger>
      </td>
      {/* <td>{content.subcats?.map((subcat) => subcat.name).join(", ")}</td> */}
    </tr>
  );
};
