import { retrieve } from "./utils.js";

// Verify Google's login token and return server token
async function verifyLoginToken(token, onLogOut) {
  try {
    const result = await retrieve(token, onLogOut, "/verify", "GET", undefined);

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export { verifyLoginToken };
