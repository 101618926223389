import { Form } from "react-bootstrap";

const ValidationAlert = ({ target }) => {
    return (
        <>
            {target && (
              <Form.Label className="text-danger">
                {target}
              </Form.Label>
            )}
        </>
    )
};

export default ValidationAlert;