import { retrieve } from "./utils.js";

// Read all subcategories of all categories
async function listCategoriesWithSubs({ token }, onLogOut) {
  try {
    const subCategoryList = await retrieve(
      token,
      onLogOut,
      "/fetch_subcats",
      "GET"
    );

    return subCategoryList;
  } catch (error) {
    return [];
  }
}
/**
 *
 * @typedef {Object} CreateSubCategoryInput
 * @property {Number} category_id
 * @property {String} sub_cat_name
 *
 * @param {CreateSubCategoryInput} subCategory
 */
async function createSubCategory({ token }, onLogOut, subCategory) {
  try {
    const createdSubCategory = await retrieve(
      token,
      onLogOut,
      "/add_subcat",
      "POST",
      subCategory
    );

    return createdSubCategory;
  } catch (error) {
    return null;
  }
}

async function updateSubCategory({ token }, onLogOut, updatedSubCat) {
  try {
    const updated = await retrieve(
      token,
      onLogOut,
      "/edit_subcat",
      "POST",
      updatedSubCat
    );

    return updated;
  } catch (error) {
    return null;
  }
}

export { listCategoriesWithSubs, createSubCategory, updateSubCategory };
