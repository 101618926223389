import Nav from "react-bootstrap/Nav";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import BootstrapNavbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import ynetLogoSrc from "../assets/icons/logo-ynet.jpg";
import { useContext } from "react";
import { loginContext } from "../LoginContext";
import GoogleLoginButton from "../components/GoogleLoginButton";

function Navbar() {
  const { user, logout } = useContext(loginContext);

  return (
    <BootstrapNavbar expand="lg" bg="dark" data-bs-theme="dark" dir="rtl">
      <Container>
        <BootstrapNavbar.Brand
          as={Link}
          to="/"
          className="ml-1rem d-flex align-items-center gap-2"
        >
          <Image src={ynetLogoSrc} className="ynet-logo-img"></Image>
          <span>אירועים אזרחיים</span>
        </BootstrapNavbar.Brand>

        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/contents">
              אירועים
            </Nav.Link>
            <Nav.Link as={Link} to="/content/new">
              הוספת אירוע
            </Nav.Link>
            <Nav.Link as={Link} to="/cats">
              ניהול קטגוריות
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              עלינו
            </Nav.Link>
          </Nav>
          {user.name ? (
            <NavDropdown
              title={
                <>
                  <Image src={user.picture} className="profile-icon-img" />
                  <span className="text-white-50"> {user.name}</span>
                </>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/" onClick={logout}>
                התנתק
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <GoogleLoginButton />
          )}
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}
export default Navbar;
