import icon_cat_1 from "../assets/icons/box.svg";
import icon_cat_2 from "../assets/icons/farm.svg";
import icon_cat_3 from "../assets/icons/housing.svg";
import icon_cat_4 from "../assets/icons/housing.svg";
import icon_cat_5 from "../assets/icons/hand.svg";
import icon_cat_6 from "../assets/icons/food.svg";
import icon_cat_7 from "../assets/icons/volunteer.svg";
import icon_cat_8 from "../assets/icons/basketball.svg";

function getSourceByCategoryId(categoryId) {
  let icon;

  switch (categoryId) {
    case 1:
      icon = icon_cat_1;
      break;
    case 2:
      icon = icon_cat_2;
      break;
    case 3:
      icon = icon_cat_3;
      break;
    case 4:
      icon = icon_cat_4;
      break;
    case 5:
      icon = icon_cat_5;
      break;
    case 6:
      icon = icon_cat_6;
      break;
    case 7:
      icon = icon_cat_7;
      break;
    case 8:
      icon = icon_cat_8;
      break;
    default:
      icon = icon_cat_2;
  }
  return icon;
}

export { getSourceByCategoryId };
