import { useState, useEffect, useContext } from "react";
import { listCategoriesWithSubs } from "../../services/CategoryService";
import { SubCategoriesList } from "../../components/SubCategoriesList";
import { loginContext } from "../../LoginContext";
import CategoryIcon from "../../components/CategoryIcon";
import Accordion from "react-bootstrap/Accordion";
import "./CategoriesPage.css";
import LoadingCover from "../../components/LoadingCover";

function CategoriesPage() {
  const [categories, setCategories] = useState([]);

  const { user, logout } = useContext(loginContext);

  useEffect(() => {
    listCategoriesWithSubs(user, logout).then((result) =>
      setCategories(result)
    );
  }, [user, logout]);

  function pushNewSubCategory(categoryId, newSubCategory) {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        return { ...category, items: [...category.items, newSubCategory] };
      }
      return category;
    });
    setCategories(updatedCategories);
  }

  function setUpdatedSubCategory(categoryId, editedSubCategory) {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        return {
          ...category,
          items: category.items.map((subCategory) => {
            if (editedSubCategory.id === subCategory.id) {
              return editedSubCategory;
            }
            return subCategory;
          }),
        };
      }
      return category;
    });

    setCategories(updatedCategories);
  }

  return categories.length === 0 ? (
    <LoadingCover />
  ) : (
    <div className="categories-background">
      <div className="container">
        <h1 className="py-3 text-center">ניהול קטגוריות</h1>
        <Accordion>
          {categories.map((category) => {
            return (
              <Accordion.Item key={category.id} eventKey={"cat-" + category.id}>
                <Accordion.Header dir="rtl">
                  <div className="d-flex justify-content-center ms-2 category-icon-container">
                    <CategoryIcon categoryId={category.id} />
                  </div>
                  <span>{category.name}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <SubCategoriesList
                    category={category}
                    pushNewSubCategory={pushNewSubCategory}
                    setUpdatedSubCategory={setUpdatedSubCategory}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default CategoriesPage;
