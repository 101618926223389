import React, { useEffect, useState, useMemo, useContext } from "react";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";

import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";

import { readContent } from "../services/ContentService";
import { getApps } from "../services/AppsService";
import { listCategoriesWithSubs } from "../services/CategoryService";
import LoadingCover from "../components/LoadingCover";
import { loginContext } from "../LoginContext";
import ConfirmationModal from "../components/ConfirmationModal";
import { calcInitialValues, onSubmit } from "./logic";
import { NEW_CONTENT_ID, ErrorTypes } from "./definitions";
import ValidationAlert from "./ValidationAlert";

function fillSubCategoriesTree(categoryId, selectedSubsIds, categoriesTree) {
  const relevantSubs = (categoriesTree || []).reduce((result, category) => {
    if (category.id === categoryId) {
      result.push(...category.items);
    } else {
      category.items.forEach((sub) => {
        if (selectedSubsIds?.includes(sub.id)) {
          result.push(sub);
        }
      });
    }
    return result;
  }, []);
  return relevantSubs;
}

function EditContent() {
  const { user, logout } = useContext(loginContext);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [apps, setApps] = useState([]);
  const [values, setValues] = useState({});

  const [submissionError, setSubmissionError] = useState("");
  const [erroredFields, setErroredFields] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const { categoryId } = values;

  const subCategoryOptionsForDisplay = useMemo(
    () =>
      fillSubCategoriesTree(
        values.categoryId,
        values.subcategory_ids,
        categoriesTree
      ) || [],
    [values.categoryId, values.subcategory_ids, categoriesTree]
  );

  const { id: itemIdOnEdit } = useParams();
  const newItemMode = itemIdOnEdit === NEW_CONTENT_ID;

  const headerLengthLimit = 26;

  useEffect(() => {
    listCategoriesWithSubs(user, logout).then((list) => {
      setCategoriesTree(list || []);
    });
    getApps(user, logout).then((apps) => setApps(apps));
    if (!newItemMode) {
      readContent(user, logout, itemIdOnEdit).then((content) => {
        setValues(calcInitialValues(content, user));
      });
    } else {
      setValues(calcInitialValues(undefined, user));
    }
  }, [newItemMode, itemIdOnEdit, user, logout]);

  const onSubmissionError = (errorType, erroredFields) => {
    if (errorType === ErrorTypes.serverError) {
      setSubmissionError("הבקשה נכשלה. נסו שנית מאוחר יותר");
    } else {
      setErroredFields(erroredFields);
      setSubmissionError("חלק מהשדות חסרים או לא תקינים");
    }
  };

  return (
    <>
      <div className="container container-lg text-end">
        {!categoriesTree.length && !newItemMode && !values.item_id && (
          <LoadingCover />
        )}
        <Row>
          <Col>
            <h1 className="py-3 text-center">
              {newItemMode ? "הוספת" : "עריכת"} אירוע
            </h1>
          </Col>
        </Row>
        <Row className="flex-row-reverse my-1 mb-5">
          <Col>
            <Form.Group>
              <Form.Label>סוג אירוע</Form.Label>
              <Form.Select
                className={`text-center ${
                  erroredFields.event_type ? "is-invalid" : ""
                }`}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    event_type: e.target.value || null,
                  }))
                }
                required
                value={values.event_type || ""}
              >
                <option value={""}>בחר סוג אירוע</option>
                <option value={"offer_help"}>הצעת עזרה</option>
                <option value={"need_help"}>צריך עזרה</option>
              </Form.Select>
              <ValidationAlert target={erroredFields.event_type} />
            </Form.Group>
          </Col>

          <Col className="text-start">
            <Row className={`${newItemMode ? "d-none" : ""}`}>
              <Col />
              <Col md="auto">
                <Form.Check
                  className={"mt-3"}
                  checked={!!values.is_active}
                  type="switch"
                  onChange={(e) =>
                    setValues((values) => ({
                      ...values,
                      is_active: !!e.target.checked,
                    }))
                  }
                  id="is_active"
                  label="פעיל"
                />
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>

        <Row className="flex-row-reverse my-1">
          <Col>
            <Form.Group>
              <Form.Label>קטגוריה</Form.Label>
              <Form.Select
                className={`text-end ${
                  erroredFields.subcategory_ids ? "is-invalid" : ""
                }`}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    categoryId: e.target.value ? +e.target.value : null,
                  }))
                }
                value={values.categoryId || ""}
              >
                <option value={""}>בחר קטגוריה</option>
                {categoriesTree.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
              <ValidationAlert target={erroredFields.subcategory_ids} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>קטגוריות משנה</Form.Label>
              <Select
                className={`text-end ${
                  erroredFields.subcategory_ids ? "border border-danger" : ""
                }`}
                disabled={!categoryId || categoryId === -1}
                placeholder="...בחר קטגוריות משנה"
                dir="rtl"
                options={subCategoryOptionsForDisplay}
                value={(values.subcategory_ids || []).map((id) => {
                  return subCategoryOptionsForDisplay.find(
                    (sub) => sub.id === id
                  );
                })}
                getOptionValue={(selection) => selection.id}
                getOptionLabel={(selection) => selection.name}
                onChange={(selectedOptions) => {
                  setValues((values) => ({
                    ...values,
                    subcategory_ids: selectedOptions.map((opt) => opt.id),
                  }));
                }}
                isMulti
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    zIndex: 3, //to go over Editor's control line
                  }),
                }}
              />
              <ValidationAlert target={erroredFields.subcategory_ids} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="flex-row-reverse  my-2">
          <Col>
            <Form.Group>
              <Form.Label>כותרת (מקסימום {headerLengthLimit} תווים)</Form.Label>
              <Form.Control
                className={`text-end ${
                  erroredFields.header ? "is-invalid" : ""
                }`}
                value={values.header || ""}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    header: e.target.value,
                  }))
                }
                dir="rtl"
                maxLength={headerLengthLimit}
                placeholder="יש להזין כותרת ראשית..."
              />
              <ValidationAlert target={erroredFields.header} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>קואורדינטה</Form.Label>
              <Form.Control
                className={`text-start ${
                  erroredFields.coordinate ? "is-invalid" : ""
                }`}
                value={values.coordinate || ""}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    coordinate: e.target.value || null,
                  }))
                }
                placeholder="Lat, Lon"
              />
              <ValidationAlert target={erroredFields.coordinate} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="flex-row-center  my-5">
          <Form.Label>תיאור</Form.Label>
          <div
            className={`text-end ${
              erroredFields.content ? "border border-danger" : ""
            }`}
          >
            <Editor
              value={values.content || ""}
              onEditorChange={(content, editor) => {
                setValues((values) => ({
                  ...values,
                  content: content,
                }));
              }}
              tinymceScriptSrc={
                process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
              }
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "preview",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; text-align:right; direction:rtl; }",
              }}
            />
          </div>
          <ValidationAlert target={erroredFields.content} />
        </Row>
        <Row className="my-2">
          <Col className="border pb-4">
            <Row className="flex-row-reverse  mt-3">
              <p className="fw-bold text-end">איש קשר</p>
            </Row>
            <Row className="flex-row-reverse  mt-1">
              <Col>
                <Form.Group>
                  <Form.Label>שם</Form.Label>
                  <Form.Control
                    value={values.contact_name || ""}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        contact_name: e.target.value,
                      }))
                    }
                    className="text-end"
                    dir="rtl"
                    placeholder="איש קשר"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>טלפון</Form.Label>
                  <Form.Control
                    value={values.contact_phone || ""}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        contact_phone: e.target.value,
                      }))
                    }
                    className="text-end"
                    placeholder="טלפון"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="flex-row-reverse  mt-3">
              <Col>
                <Form.Group>
                  <Form.Label>אימייל</Form.Label>
                  <Form.Control
                    value={values.contact_email || ""}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        contact_email: e.target.value,
                      }))
                    }
                    className="text-end"
                    placeholder="אימייל"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>אתר אינטרנט</Form.Label>
                  <Form.Control
                    value={values.external_link || ""}
                    onChange={(e) =>
                      setValues((values) => ({
                        ...values,
                        external_link: e.target.value,
                      }))
                    }
                    className="text-end"
                    placeholder="אתר אינטרנט"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col></Col>
          <Col>
            <Form.Group>
              <Form.Label>לינק לדיווח</Form.Label>
              <Form.Control
                value={values.report_link || ""}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    report_link: e.target.value,
                  }))
                }
                className="text-end"
                placeholder="לינק לדיווח"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row
          className={`flex-row-reverse my-3 text-center ${
            user.appId > 0 ? "d-none" : ""
          }`}
        >
          <Col />
          <Col md="auto">
            <Form.Group>
              <Form.Label>פלטפורמה לפרסום</Form.Label>
              <Form.Select
                className={`text-center ${
                  erroredFields.app_ids ? "is-invalid" : ""
                }`}
                onChange={(e) =>
                  setValues((values) => ({
                    ...values,
                    app_ids: e.target.value ? [+e.target.value] : [],
                  }))
                }
                required
                value={values.app_ids?.[0]}
              >
                <option value={""}>בחר פלטפורמה לפרסום</option>
                {apps.map((app) => (
                  <option value={app.id} key={app.id}>
                    {app.name}
                  </option>
                ))}
              </Form.Select>
              <ValidationAlert target={erroredFields.app_ids} />
            </Form.Group>
          </Col>
          <Col />
        </Row>
        {submissionError && (
          <Row>
            <Col>
              <div className="alert alert-danger" role="alert">
                {submissionError}
              </div>
            </Col>
          </Row>
        )}
        <Row className={"mt-4 pb-3"}>
          <Col>
            <Button
              className={"w-100"}
              variant="primary"
              onClick={() =>
                onSubmit({
                  values,
                  onSuccess: () => setShowConfirm(true),
                  setError: onSubmissionError,
                  user,
                  logout,
                })
              }
            >
              שמירה
            </Button>
          </Col>
          <Col>
            <Link to="/contents">
              <Button className={"w-100"} variant="danger">
                ביטול
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <ConfirmationModal show={showConfirm} />
    </>
  );
}

export default EditContent;
