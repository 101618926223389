import React, { useState, useContext } from "react";
import { AddSubCategory } from "./AddSubCategory";
import { EditedSubCategory } from "./EditedSubCategory";
import {
  createSubCategory,
  updateSubCategory,
} from "../services/CategoryService";
import { loginContext } from "../LoginContext";
import "./SubCategoriesList.css";

const NOT_IN_EDIT_MODE = 0;
export const SubCategoriesList = ({
  category,
  pushNewSubCategory,
  setUpdatedSubCategory,
}) => {
  const { user, logout } = useContext(loginContext);
  const [editedSubCategoryId, setEditedSubCategoryId] =
    useState(NOT_IN_EDIT_MODE);

  function addSubCategory(categoryId, subCategoryName) {
    createSubCategory(user, logout, {
      cat_id: categoryId,
      name: subCategoryName,
    }).then((result) => {
      pushNewSubCategory(categoryId, {
        id: result.subcat_id,
        name: subCategoryName,
      });
    });
  }

  function handleUpdatedSubCategory(subCategoryId, subCategoryName) {
    updateSubCategory(user, logout, {
      subcat_id: subCategoryId,
      name: subCategoryName,
    }).then((result) => {
      setUpdatedSubCategory(category.id, {
        id: subCategoryId,
        name: subCategoryName,
      });
      setEditedSubCategoryId(NOT_IN_EDIT_MODE);
    });
  }

  const getRenderedList = () => {
    if (category.items.length === 0) {
      return <li className="list-group-item">אין תת-קטגוריות</li>;
    }

    return category.items.map((subCategory) => (
      <li
        key={subCategory.id}
        className="list-group-item d-flex justify-content-between edit-mode"
      >
        {subCategory.id === editedSubCategoryId ? (
          <EditedSubCategory
            subCategory={subCategory}
            handleUpdatedSubCategory={handleUpdatedSubCategory}
          />
        ) : (
          <>
            <p className="mt-2">{subCategory.name}</p>
            {editedSubCategoryId === NOT_IN_EDIT_MODE && (
              <button
                className="btn btn-outline-secondary rounded-5"
                onClick={() => {
                  setEditedSubCategoryId(subCategory.id);
                }}
              >
                עריכה&nbsp;✎
              </button>
            )}
          </>
        )}
      </li>
    ));
  };

  return (
    <>
      <ul
        className="list-group list-group-flush border-start border-1 shadow-sm ps-4"
        dir="rtl"
      >
        {getRenderedList()}
      </ul>
      <AddSubCategory
        categoryId={category.id}
        addSubCategory={addSubCategory}
      ></AddSubCategory>
    </>
  );
};
