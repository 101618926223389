import React, { useState } from "react";
import "./AddSubCategory.css";

export const AddSubCategory = ({ categoryId, addSubCategory }) => {
  const [inputValue, setInputValue] = useState("");

  const addNewSubCategory = () => {
    addSubCategory(categoryId, inputValue);
    setInputValue("");
  };

  return (
    <div className="container add-size pt-3">
      <div className="row">
        <button
          className="btn btn-primary btn-size col-1"
          onClick={addNewSubCategory}
        >
          הוספה
        </button>
        <input
          type="text"
          placeholder="תת-קטגוריה"
          className="form-control col"
          dir="rtl"
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
        />
      </div>
    </div>
  );
};
