import { retrieve } from "./utils.js";

// List all content items
async function getApps(user, onLogOut) {
  const { appId, token } = user || {};
  try {
    const list = await retrieve(token, onLogOut, "/fetch_apps", "GET");
    if (appId) {
      return list.filter((app) => app.id === appId);
    }
    return list;
  } catch (error) {
    return [];
  }
}

export { getApps };
