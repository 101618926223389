import React from "react";
import { useState, useEffect } from "react";
import { ContentsPage } from "./components/ContentsPage";
import EditContent from "./EditContent/EditContent";
import CategoriesPage from "./pages/CategoriesPage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import { loginContext } from "./LoginContext";
import { googleLogout } from "@react-oauth/google";
import { USER_LOCAL_STORAGE_NAME } from "./definitions";
import { verifyLoginToken } from "./services/LoginService";
import { jwtDecode } from "jwt-decode";
import { About } from "./components/About";
import AuthWrapper from "./components/AuthWrapper";
import LoadingCover from "./components/LoadingCover";

function extractUser(token) {
  const { name, picture, app_id } = jwtDecode(token);
  return { token, name, picture, appId: +app_id || 0 };
}

export default function App() {
  const [user, setUser] = useState({
    token: "",
    name: "",
    picture: "",
    app_id: -1,
  });
  const [pastLoginLoaded, setPastLoginLoaded] = useState(false);

  function logout() {
    googleLogout();
    localStorage.removeItem(USER_LOCAL_STORAGE_NAME);
    setUser({ token: "", name: "", picture: "", appId: -1 });
  }

  function login(googleCredentialToken) {
    if (!googleCredentialToken) {
      return;
    }

    verifyLoginToken(googleCredentialToken, logout)
      .then((response) => {
        if (response.status === "success") {
          const userToken = response.token;
          localStorage.setItem(USER_LOCAL_STORAGE_NAME, userToken);
          setUser(extractUser(userToken));
        } else {
          logout();
        }
      })
      .catch((err) => {
        console.log("Unauthorized User");
      });
  }

  useEffect(() => {
    const userToken = localStorage.getItem(USER_LOCAL_STORAGE_NAME);
    if (userToken) {
      setUser(extractUser(userToken));
    }
    setPastLoginLoaded(true);
  }, []);

  return (
    <React.StrictMode>
      <loginContext.Provider value={{ user, login, logout }}>
        {!pastLoginLoaded ? (
          <LoadingCover />
        ) : (
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route
                index
                element={
                  <AuthWrapper element={<Navigate to="/contents" replace />} />
                }
              />
              <Route
                path="/content/:id"
                element={<AuthWrapper element={<EditContent />} />}
              />
              <Route
                path="/contents"
                element={<AuthWrapper element={<ContentsPage />} />}
              />
              <Route
                path="/cats"
                element={<AuthWrapper element={<CategoriesPage />} />}
              />
              <Route path="/about" element={<About />}></Route>
            </Routes>
          </BrowserRouter>
        )}
      </loginContext.Provider>
    </React.StrictMode>
  );
}
